import { getCurrentInstance, watch, toRefs, reactive, ref } from "vue";
import { useRouter } from 'vue-router'
import { dateToStrCopy } from '@/utils/date'
import Compressor from 'compressorjs'
import { baseUrlImg } from "../../utils/common";

export default {
	setup(props, context) {
		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		
		const router = useRouter();

		const dataAll = reactive({
			test: ref(null),
			dateTitle: "请选择时间",
			minDate: new Date(2010, 0, 1),
			maxDate: new Date(2110, 11, 31),
			currentDate: new Date(),
			dateTitleEn: "请选择时间",
			minDateEn: new Date(2010, 0, 1),
			maxDateEn: new Date(2110, 11, 31),
			currentDateEn: new Date(),
			showDate: ref(false),
			showDateEnd: ref(false),
			xkImgList: ref([]),
			yyImgList: ref([]),
			enterpriseGameList: ref(null),
			enterpriseGameEn: ref("请选择所属辖区"),
			enterpriseGameShow: ref(false),
			enterpriseUserEn: ref("请选择监管人员"),
			enterpriseUserShow: ref(false),
			enterpriseUserList: ref([]),
			enterpriseUserResult: ref([]),
			enterpriseUserResultName: ref([]),
			enterpriseLevelEn: ref("请选择监管等级"),
			enterpriseLevelList: ref(null),
			enterpriseLevelShow: ref(false),
			enterpriseSurfaceSrcList: ref([]),
			enterpriseStoreSrcList: ref([]),
			mtImgList: ref([]),
			snImgList: ref([]),
			enterpriseStateEn: ref("请选择企业类型"),
			enterpriseStateList: ref(null),
			enterpriseStateShow: ref(false),
			enterpriseLicenceStimeEn: ref("开始日期"),
			enterpriseLicenceEtimeEn: ref("结束日期"),
			enterpriseAddressShow: ref(false),
			//地图
			keyWords: ref(null),
			coordinate: ref(null),
			enterpriseAddressTemp: ref(null),
			enterpriseLongitudeTemp: ref(null),
			enterpriseLatitudeTemp: ref(null),
			enterpriseAddressText: ref("请选择经纬度"),
			productionCategoryShow: ref(false),
			productionCategoryEn: ref("请选择食品生产类别"),
			productionCategoryList: ref([]),
			productionCategoryResult: ref([]),
			productionCategoryResultName: ref([]),


			enterpriseModel: ref("1"),
			enterpriseQrNumber: proxy.$router.currentRoute.value.params.type,
			enterpriseName: ref(null),
			enterpriseType: ref(0),
			enterpriseLicenceStime: ref(null),
			enterpriseLicenceEtime: ref(null),
			enterpriseNumber: ref(null),
			enterpriseLicence: ref(null),
			enterpriseLicenceSrc: ref(null),
			enterpriseLicense: ref(null),
			cateringBody: ref(null),
			enterpriseContact: ref(null),
			enterpriseContactTel: ref(null),
			enterpriseHead: ref(null),
			enterpriseSecurity: ref(null),
			enterpriseGame: ref(null),
			enterpriseUser: ref(null),
			enterpriseLevel: ref(null),
			enterpriseSurfaceSrc: ref(null),
			enterpriseStoreSrc: ref(null),
			enterpriseState: proxy.$router.currentRoute.value.params.type.indexOf("G") == -1 ? "0" : "1",
			enterpriseAddress: ref(null),
			enterpriseLongitude: ref(null),
			enterpriseLatitude: ref(null),
			enterpriseHeadTel: ref(null),
			productionCategory: ref(null),
			enterpriseSecurityTel: ref(null),
			enterpriseWarehouseAddress: ref(null),
			enterpriseMinCheck: ref(null),
			
			showAddList: ref(false),
			addressArr: ref([])
		});

		const fan = () => { router.go(-1); }

		const showDateButton = () => {
			dataAll.showDate = true;
		}
		const dateCancel = () => {
			dataAll.showDate = false;
		}
		const dateConfirm = (value) => {
			dataAll.enterpriseLicenceStimeEn = dateToStrCopy(new Date(value));
			dataAll.enterpriseLicenceStime = dateToStrCopy(new Date(value))
			dataAll.showDate = false;
		}

		const showDateButtonEnd = () => {
			dataAll.showDateEnd = true;
		}
		const dateCancelEnd = () => {
			dataAll.showDateEnd = false;
		}
		const dateConfirmEnd = (value) => {
			dataAll.enterpriseLicenceEtime = dateToStrCopy(new Date(value))
			dataAll.enterpriseLicenceEtimeEn = dateToStrCopy(new Date(value));
			dataAll.showDateEnd = false;
		}

		const addXkImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						cache: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseLicenceSrc = data.data;
							dataAll.xkImgList = [{url: baseUrlImg()+ dataAll.enterpriseLicenceSrc}]
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})	
		}
		const xkImg = () => {
			dataAll.enterpriseLicenceSrc = ref(null);
			dataAll.xkImgList = ref([])
		}

		const addYyImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						async: false,
						cache: false,
						contentType: false,
						processData: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseLicense = data.data;
							dataAll.yyImgList = [{url: baseUrlImg() + dataAll.enterpriseLicense }]
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})		
		}
		const yyImg = () => {
			dataAll.enterpriseLicense = ref(null);
			dataAll.yyImgList = ref([])
		}

		const selectEnterpriseGame = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getGame`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					const array = [];
					for (var i = 0; i < data.type.length; i++) {
						array.push({
							text: data.type[i].gameName,
							id: data.type[i].gameId
						});
					}
					dataAll.enterpriseGameList = array;
					dataAll.enterpriseGameShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const enterpriseGameCancel = () => {
			dataAll.enterpriseGameShow = false;
		}
		const enterpriseGameConfirm = (value) => {
			if (dataAll.enterpriseGame != value.id) {
				dataAll.enterpriseUser = null;
				dataAll.enterpriseUserResult = [];
				dataAll.enterpriseUserResultName = [];
				dataAll.enterpriseUserEn = "请选择监管人员";
			}
			dataAll.enterpriseGameEn = value.text;
			dataAll.enterpriseGame = value.id;
			dataAll.enterpriseGameShow = false;
		}

		const selectEnterpriseUser = () => {
			if (!dataAll.enterpriseGame) {
				proxy.$toast.fail("请先选择所属辖区");
				return;
			}
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/user/listAll`),
				method: 'post',
				data: {
					'userGame': dataAll.enterpriseGame
				},
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					dataAll.enterpriseUserList = data.list;
					dataAll.enterpriseUserShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const enterpriseUserCancel = () => {
			dataAll.enterpriseUserShow = false;
			dataAll.enterpriseUserResult = [];
		}
		const enterpriseUserConfirm = (value) => {
			dataAll.enterpriseUserResultName = []
			dataAll.enterpriseUserList.forEach(item => {
				dataAll.enterpriseUserResult.forEach(_item => {
					if (item.userId == _item) {
						dataAll.enterpriseUserResultName.push(item.userName);
					}
				})
			})
			dataAll.enterpriseUserEn = dataAll.enterpriseUserResultName? 
						(dataAll.enterpriseUserResultName.length>1? dataAll.enterpriseUserResultName.join('、') : dataAll.enterpriseUserResultName[0])
						:'请选择监管人员';
			dataAll.enterpriseUserShow = false;
		}
		const toggle = (item) => {
			const index = dataAll.enterpriseUserResult.indexOf(item.userId);
			if (index == -1) {
				dataAll.enterpriseUserResult.push(item.userId);
			} else {
				dataAll.enterpriseUserResult.splice(index, 1);
			}
		}

		const changeGroup = (val) => {
			dataAll.enterpriseUserResult = val;
		}

		const selectEnterpriseLevel = () => {
			dataAll.enterpriseLevelList = [
				{ text: "A" }, 
				{ text: "B" }, 
				{ text: "C" }
			];
			dataAll.enterpriseLevelShow = true;
		}
		const enterpriseLevelCancel = () => {
			dataAll.enterpriseLevelShow = false;
		}
		const enterpriseLevelConfirm = (value) => {
			console.log(value);
			dataAll.enterpriseLevelEn = value.text;
			dataAll.enterpriseLevel = value.text;
			dataAll.enterpriseLevelShow = false;
		}

		const addMtImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						async: false,
						cache: false,
						contentType: false,
						processData: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseSurfaceSrcList.push(data.data);
							dataAll.enterpriseSurfaceSrc = dataAll.enterpriseSurfaceSrcList.join(',');
							dataAll.mtImgList = dataAll.enterpriseSurfaceSrcList.map(mt=>{
								return { url: baseUrlImg() + mt }
							})
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})	
		}
		const mtImg = (file, detail) => {
			dataAll.enterpriseSurfaceSrcList.splice(detail.index, 1);
			dataAll.enterpriseSurfaceSrc = dataAll.enterpriseSurfaceSrcList.join(',');
			dataAll.mtImgList = dataAll.enterpriseSurfaceSrcList.map(mt=>{
				return { url: baseUrlImg() + mt }
			})
		}
		const addSnImg = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						async: false,
						cache: false,
						contentType: false,
						processData: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.enterpriseStoreSrcList.push(data.data);
							dataAll.enterpriseStoreSrc = dataAll.enterpriseStoreSrcList.join(',');
							dataAll.snImgList = dataAll.enterpriseStoreSrcList.map(sn=>{
								return { url: baseUrlImg() + sn }
							})
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})
		}
		const snImg = (file, detail) => {
			dataAll.enterpriseStoreSrcList.splice(detail.index, 1);
			dataAll.enterpriseStoreSrc = dataAll.enterpriseStoreSrcList.join(',');
			dataAll.snImgList = dataAll.enterpriseStoreSrcList.map(sn=>{
				return { url: baseUrlImg() + sn }
			})
		}

		const addE = () => {
			// let json1 = JSON.stringify(dataAll.enterpriseUserResult).trim();
			// let json2 = JSON.stringify(dataAll.productionCategoryResult).trim();
			const json = {
				enterpriseModel: dataAll.enterpriseModel,
				enterpriseName: dataAll.enterpriseName,
				enterpriseType: dataAll.enterpriseType,
				enterpriseLicenceStime: dataAll.enterpriseLicenceStime,
				enterpriseLicenceEtime: dataAll.enterpriseLicenceEtime,
				enterpriseNumber: dataAll.enterpriseNumber,
				enterpriseLicence: dataAll.enterpriseLicence,
				enterpriseLicenceSrc: dataAll.enterpriseLicenceSrc,
				enterpriseLicense: dataAll.enterpriseLicense,
				enterpriseContact: dataAll.enterpriseContact,
				enterpriseContactTel: dataAll.enterpriseContactTel,
				enterpriseHead: dataAll.enterpriseHead,
				enterpriseSecurity: dataAll.enterpriseSecurity,
				enterpriseGame: dataAll.enterpriseGame,
				enterpriseUser: dataAll.enterpriseUserResult.join(','),
				enterpriseLevel: dataAll.enterpriseLevel,
				enterpriseSurfaceSrc: dataAll.enterpriseSurfaceSrc,
				enterpriseStoreSrc: dataAll.enterpriseStoreSrc,
				enterpriseState: dataAll.enterpriseState,
				enterpriseModel: dataAll.enterpriseModel,
				enterpriseQrNumber: dataAll.enterpriseQrNumber,
				enterpriseAddress: dataAll.enterpriseAddress,
				enterpriseLongitude: dataAll.enterpriseLongitude,
				enterpriseLatitude: dataAll.enterpriseLatitude,
				enterpriseHeadTel: dataAll.enterpriseHeadTel,
				productionCategory: dataAll.productionCategoryResult.join(','),
				enterpriseMinCheck: dataAll.enterpriseMinCheck,
				enterpriseWarehouseAddress: dataAll.enterpriseWarehouseAddress,
				enterpriseSecurityTel: dataAll.enterpriseSecurityTel
			}

			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/addUpdateEnterprise`),
				method: 'post',
				data: json,
				headers: {
					token: sessionStorage.getItem("userToken")
				},
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					router.push({
						path: '/enterpriseJump/' + dataAll.enterpriseQrNumber
					});
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}

		let geolocation = new qq.maps.Geolocation("6R5BZ-HBYWQ-XW25T-GFO7X-UMMLS-FEFXU", "qr");
		let options = { timeout: 9000 };
		let map = {};
		let marker = {};
		//定义工厂模式函数
		var mapOptions = {
			zoom: 15, //设置地图缩放级别
			// center: myLatlng, //设置中心点样式
			mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
		}

		let showErr = () => {}

		let showPosition = (position) => {
			if (!dataAll.enterpriseLatitude && !dataAll.enterpriseLongitude) {
				dataAll.enterpriseAddressTemp = position.nation + position.city + position.addr;
				dataAll.enterpriseLongitudeTemp = position.lng;
				dataAll.enterpriseLatitudeTemp = position.lat;
			}

			//步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
			//设置地图中心点
			var myLatlng = new qq.maps.LatLng(dataAll.enterpriseLatitudeTemp, dataAll.enterpriseLongitudeTemp);
			mapOptions.center = myLatlng

			//获取dom元素添加地图信息
			map = new qq.maps.Map(document.getElementById("container"), mapOptions);

			//创建一个Marker
			marker = new qq.maps.Marker({
				//设置Marker的位置坐标
				position: myLatlng,
				//设置显示Marker的地图
				map: map
			});

			//给地图添加点击事件
			//并获取鼠标点击的经纬度
			qq.maps.event.addListener(map, 'click', function(event) {
				//创建一个Marker
				marker.setMap(null);
				marker = new qq.maps.Marker({
					//设置Marker的位置坐标
					position: new qq.maps.LatLng(event.latLng.getLat(), event.latLng.getLng()),
					//设置显示Marker的地图
					map: map
				});
				dataAll.enterpriseLongitudeTemp = event.latLng.getLng();
				dataAll.enterpriseLatitudeTemp = event.latLng.getLat();
				proxy.$http({
					url: proxy.$http.adornUrl(`/app/qr/getAddress`),
					method: 'post',
					data: {
						enterpriseLongitude: event.latLng.getLng(),
						enterpriseLatitude: event.latLng.getLat()
					},
					headers: {
						token: sessionStorage.getItem("userToken")
					},
				}).then(({
					data
				}) => {
					if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
						});
					}
					dataAll.enterpriseAddressTemp = data.data.result.address + data.data.result.formatted_addresses.recommend;
				})
			});
		}

		const selectEnterpriseAddress = () => {
			dataAll.enterpriseAddressShow = true;
			window.setTimeout(function() {
				geolocation.getLocation(showPosition, showErr, options);
			}, 380);
		}
		const enterpriseAddressCancel = () => {
			dataAll.enterpriseAddressShow = false;
		}
		const enterpriseAddressConfirm = () => {
			dataAll.enterpriseAddress = dataAll.enterpriseAddressTemp;
			dataAll.enterpriseLongitude = dataAll.enterpriseLongitudeTemp;
			dataAll.enterpriseLatitude = dataAll.enterpriseLatitudeTemp;
			dataAll.enterpriseAddressText = dataAll.enterpriseLatitudeTemp + "," + dataAll.enterpriseLongitudeTemp;
			dataAll.enterpriseAddressShow = false;
		}

		const queryAddressByKeyWord = () => {
			let keyword = dataAll.enterpriseAddressTemp
			proxy.$http.request({
				url: proxy.$http.adornUrl(`/app/qr/getDegreeList?address=${keyword}`),
			    method: 'get',
			    headers:{
			      	token:sessionStorage.getItem("userToken")
			    }
			}).then(({data}) => {
				if(data&&data.code==0) {
					let addArr = data.data.map(a=>{ return { id: a.id, address: a.address, location: a.location }})
					dataAll.addressArr = addArr
					dataAll.showAddList = true
				}else if(data.code===401){
					sessionStorage.setItem("userToken","");
			        router.push({path:'/enterpriseJump/'+proxy.$router.currentRoute.value.params.id});
				}else{
					proxy.$toast.fail(data.msg);
				}
			})
		}

		const selectAddItem = (item) => {
			dataAll.showAddList = false
			if(item.address) {
				dataAll.enterpriseLatitudeTemp = item.location.lat
				dataAll.enterpriseLongitudeTemp = item.location.lng
				dataAll.enterpriseAddressTemp = item.address
				let centerPoint = new qq.maps.LatLng(dataAll.enterpriseLatitudeTemp, dataAll.enterpriseLongitudeTemp);
				if(map) {
					map.setCenter(centerPoint)
					marker.setPosition(centerPoint)
				} 
			}
		}

		const selectProductionCategory = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getCategory`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					let array = [];
					for (var i = 0; i < data.type.length; i++) {
						array.push({
							text: data.type[i].value,
							id: data.type[i].key
						});
					}
					dataAll.productionCategoryList = array;
					dataAll.productionCategoryShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.type
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const productionCategoryCancel = () => {
			dataAll.productionCategoryShow = false;
			dataAll.productionCategoryResult = [];
		}
		const productionCategoryConfirm = (value) => {
			dataAll.productionCategoryResultName = []
			dataAll.productionCategoryList.forEach(item => {
				dataAll.productionCategoryResult.forEach(_item => {
					if (item.userId == _item) {
						dataAll.productionCategoryResultName.push(item.value);
					}
				})
			})
			dataAll.productionCategoryEn = dataAll.productionCategoryResultName.join('、');
			if (dataAll.enterpriseUserEn.length <= 0) {
				dataAll.productionCategoryEn = "请选择食品生产类别";
			} else {
				dataAll.productionCategoryEn = "您选择了" + dataAll.productionCategoryResult.length + "个";
			}
			dataAll.productionCategoryShow = false;
		}
		const productionCategoryToggle = (item) => {
			const index = dataAll.productionCategoryResult.indexOf(item.id);
			// if(index==-1){
			//     dataAll.productionCategoryResult.push(item.id);
			//     dataAll.productionCategoryResultName.push(item.text);
			// }else{
			//     dataAll.productionCategoryResult.splice(index,1);
			//     dataAll.productionCategoryResultName.splice(index,1);
			// }
			// dataAll.productionCategory = dataAll.productionCategoryResult.join(",");

			// const index = dataAll.productionCategoryResult.indexOf(item.id);
			if (index == -1) {
				dataAll.productionCategoryResult.push(item.id);
			} else {
				dataAll.productionCategoryResult.splice(index, 1);
			}
		}

		const productionCategoryChange = (val) => {
			dataAll.productionCategoryResult = val;
		}

		return {
			...toRefs(dataAll),
			fan,
			showDateButton,
			showDateButtonEnd,
			dateCancel,
			dateConfirm,
			dateCancelEnd,
			dateConfirmEnd,
			addXkImg,
			xkImg,
			addYyImg,
			yyImg,
			selectEnterpriseGame,
			enterpriseGameCancel,
			enterpriseGameConfirm,
			selectEnterpriseUser,
			enterpriseUserCancel,
			enterpriseUserConfirm,
			toggle,
			changeGroup,
			selectEnterpriseLevel,
			enterpriseLevelCancel,
			enterpriseLevelConfirm,
			addMtImg,
			mtImg,
			addSnImg,
			snImg,
			addE,
			selectEnterpriseAddress,
			enterpriseAddressCancel,
			enterpriseAddressConfirm,
			queryAddressByKeyWord,
			selectAddItem,
			selectProductionCategory,
			productionCategoryCancel,
			productionCategoryConfirm,
			productionCategoryToggle,
			productionCategoryChange
		}
	}
}
